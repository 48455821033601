import DateHelper from '@/helpers/date.helper';

import LeadTracking from '@/services/lead.tracking.service';

const service = new LeadTracking();

export default {
    name: "LeadTrackingList",

    data() {
        return {
            trackings: [],
            tracking: {
                DefaultSource: "",
                LeadSource: ""
            },
            createModal: false,
            editModal: false,
            deleteModal: false,
            errors: {},
            DefaultSources: [
                {value: "Fresh Up", text: "Fresh Up"},
                {value: "Phone Up", text: "Phone Up"},
                {value: "Internet", text: "Internet"},
                {value: "Call Center", text: "Call Center"},
                {value: "Chat", text: "Chat"},
                {value: "Radio", text: "Radio"},
                {value: "Television", text: "Television"},
                {value: "Turn", text: "Turn"},
                {value: "Dealer Mgmt Sys", text: "Dealer Mgmt Sys"},
                {value: "Marketing/Biz Rule", text: "Marketing/Biz Rule"},
                {value: "Lease Return", text: "Lease Return"},
                {value: "History Import", text: "History Import"},

                {value: "Precise Price", text: "Precise Price"},
                {value: "No Customer Initiated", text: "No Customer Initiated"},
            ],
            currentPage: 1,
            perPage: 25,
            totalRows: 0,
        }
    },

    async mounted() {
        await this.list();
    },

    methods: {

        async list(page) {
            this.currentPage = page || 1;

            const skip = this.perPage * (this.currentPage - 1);

            const res = await service.list(this.perPage, skip);
            if (res && !res.error) {
                this.trackings = res.data.trackings;
                this.totalRows = res.data.count;
            }
        },

        async manage() {
            this.tracking._id ? await this.edit() : await this.create();
        },

        async create() {
            const res = await service.create(this.tracking);
            if (res && !res.error) {
                await this.list();
                this.cancelModal();
                this.$notify({
                    type: 'success',
                    message: this.$t('tracking_create_ok')
                });
            }
        },

        async edit() {
            const res = await service.edit(this.tracking);
            if (res && !res.error) {
                const index = this.trackings.findIndex(tracking => tracking._id === this.tracking._id);
                if (index > -1) {
                    this.trackings[index] = this.tracking;
                }
                this.cancelModal();
                this.$notify({
                    type: 'success',
                    message: this.$t('tracking_edit_ok')
                });
            }
        },

        async remove() {
            const res = await service.delete(this.tracking._id);
            if (res && !res.error) {
                this.cancelModal();
                await this.list();
                this.$notify({
                    type: 'success',
                    message: this.$t('tracking_delete_ok')
                });
            } else if (res.data.assigned) {
                this.cancelModal();
                this.$notify({
                    type: 'error',
                    message: this.$t('tracking_delete_failed')
                });
            }
        },

        async openModal(data = {}, action = "edit") {
            this.tracking = {...data};

            if (action === 'create') {
                this.createModal = true;
            } else if (action === 'edit') {
                this.editModal = true;
            } else if (action === 'delete') {
                this.deleteModal = true;
            }
        },

        cancelModal() {
            this.createModal = false;
            this.editModal = false;
            this.deleteModal = false;
            this.tracking = {};
            this.errors = {};
        },

        async setPerPage(num) {
            this.perPage = +(num);
            this.$hideModals();
            await this.list();
        },

        next() {
            this.list(this.currentPage + 1);
        },

        prev() {
            this.list(this.currentPage - 1);
        },
    },

    filters: {

        normalizeDate(date) {
            if (!date) return "N/A";
            return DateHelper.customFormat(date, "DD MMM YYYY");
        },

    },

    watch: {}
};